import React from "react";

const ErrorWarning = ({ message }) => {
  return (
    <div className="flex items-center bg-rose-100 text-[#a94442] border border-rose-700 rounded-lg p-4 mt-5 shadow-sm">

      <div className="flex-1">
        <h3 className="text-lg font-medium mb-1">Unable to Load Data</h3>
        <p className="text-rose-800 text-sm mb-2">
          {message || "We couldn't load your data due to high volume activity."}
        </p>
        <p className="text-sm text-rose-800 mb-3">Please try reloading or check back later, thanks for your patience!</p>
        
        <button 
          onClick={() => window.location.reload()} 
          className="bg-rose-600 hover:bg-rose-500 text-white py-2 px-4 rounded-md text-sm transition-colors duration-200"
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default ErrorWarning;