import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {setToolsSort, setToolsSortType} from "../../reducers/tools.reducer";
import {SortAscending, SortDescending, SortIcon} from "../../utils/image";

const TableHeader = ({item, index}) => {
    const dispatch = useDispatch()
    const [sortState, setSortState] = useState(true)
    const {sortType, sortBy} = useSelector(state => state.toolsReducer)

    const sortClickHandler = () => {
        setSortState(!sortType)
        dispatch(setToolsSort(item.dataIndex))
        dispatch(setToolsSortType(!sortType))
    }

    return (
        <div className={"row items-center md:space-x-2 2k:space-x-5 4k:space-x-10 space-x-1 2k:h-16 4k:h-32"}>
            <p className={`row items-center md:h-12 h-7 text-dark opacity-60 font-light ${index === 0 && 'pl-4 4k:pl-10'}`}>{item.tableHead}</p>
            {
                item.sorting ? <img src={item.dataIndex.includes(sortBy) ? sortState ? SortAscending : SortDescending : SortIcon } onClick={item.sorting ? sortClickHandler : undefined} className={"md:w-3 2k:w-5 4k:w-8 cursor-pointer"} alt="Index Sorting"/> : null
            }
        </div>
    )
}

export default TableHeader
