import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCurrentPage} from "../reducers/tools.reducer";

const PaginationComponent = (props) => {
    const dispatch = useDispatch()
    const {slug} = props
    const {currentPage, pageSize, totalData} = useSelector(state => state.toolsReducer)
    const [pagination, setPagination] = useState([])
    const [totalPage, setTotalPage] = useState(0)

    useEffect(() => {
        if (pageSize > 0) {
            let total = Math.ceil(totalData / pageSize)
            let j = total > 5 ? 5 : total
            let pag = []

            for (let i = 1; i <= j; i++) {
                pag.push(i)
            }

            setTotalPage(total)
            setPagination(pag)
        }
    }, [totalData, pageSize])

    const valueCheck = (value) => {
        switch (value) {
            case 1:
            case totalPage:
                return true;
            default:
                return false
        }
    }

    const onChangePagination = (value, index) => {
        if ((value === 2 || value === 3) && index !== 0) {
            dispatch(setToolsCurrentPage(value))
        } else if ((valueCheck(value) && pagination.includes(value))) {
            dispatch(setToolsCurrentPage(value))
        } else {
            generatePagination(value)
        }
    }

    const generatePagination = (value) => {
        if (value === totalPage) {
            generatePaginationMin(value)
        } else {
            if (value === 2 || value === 1) {
                setPagination([1, 2, 3, 4, 5])
            } else {
                let baris = []
                let a = value === (totalPage - 1) ? value - 3 : value - 2
                let b = value === (totalPage - 1) ? totalPage + 1 : value + 3
                for (let i = a; i < b; i++) {
                    baris.push(i)
                }
                setPagination(baris)
            }
            dispatch(setToolsCurrentPage(value))
        }
    }

    const generatePaginationMin = (value) => {
        let baris = []

        dispatch(setToolsCurrentPage(value))
        for (let i = value; i > value - 5; i--) {
            baris.push(i)
        }
        baris.reverse()
        setPagination(baris)
    }

    const backgroundPagination = slug === 'christmas' ?  'bg-christmas-green' : 'purple-gradient'

    return (
        <div className={"my-10 flex flex-row items-center justify-end space-x-1 2k:text-xl"}>
            {currentPage !== 1 &&
                <button onClick={() => onChangePagination(currentPage - 1)}
                        className={`w-8 h-8 2k:w-10 2k:h-10 flex justify-center items-center rounded border bg-white hover:${backgroundPagination}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5"
                         stroke="currentColor" className="w-4 h-4 hover:stroke-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
                    </svg>
                </button>
            }
            {totalData > 0 && !pagination.includes(1) && <button onClick={() => onChangePagination(1)}
                                                                 className={`w-8 h-8 2k:w-10 2k:h-10 flex justify-center items-center rounded-md hover:bg-christmas-green hover:text-white font-extrabold`}>1
            </button>}
            {totalData > 0 && !pagination.includes(1) && <div>...</div>}
            {
                pagination.map((item, index) =>
                    <button key={index} onClick={() => onChangePagination(item, index)}
                            className={`${(currentPage === item) ? `${backgroundPagination} text-white` : 'bg-white'} w-8 h-8 2k:w-10 2k:h-10 flex justify-center hover:${backgroundPagination} hover:text-white border items-center rounded font-extrabold`}>{item}
                    </button>
                )
            }
            {totalData > 0 && !pagination.includes(totalPage) && <div>...</div>}
            {totalData > 0 && !pagination.includes(totalPage) && <button onClick={() => onChangePagination(totalPage)}
                                                                         className={`w-8 h-8 flex justify-center bg-white items-center font-extrabold hover:${backgroundPagination} hover:text-white border rounded-md active:text-white`}>{totalPage}
            </button>}
            {totalData > 0 && currentPage !== totalPage &&
                <button onClick={() => onChangePagination(currentPage + 1)}
                        className={`w-8 h-8 2k:w-10 2k:h-10 flex justify-center items-center rounded border bg-white hover:stroke-white hover:${backgroundPagination}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5"
                         stroke="currentColor" className="w-4 h-4 hover:stroke-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
                    </svg>
                </button>
            }
        </div>
    )
}

export default PaginationComponent
