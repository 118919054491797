import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setToolsSearchKey} from "../reducers/tools.reducer";
import {SearchIcon} from "../utils/image";

const SearchComponent = () => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const {searchKey} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        if(searchKey.length > 0){
            setSearch(searchKey)
        }
    }, [searchKey])

    const onSearchHandler = (value) => {
        setSearch(value)
    }

    const inputHandler = (key) => {
        if (key === "Enter") {
            dispatch(setToolsSearchKey(search))
        }
    }

    return (
        <div className={"row items-center md:w-72 2k:w-1/3 w-full h-10 2k:h-16 4k:h-32 border bg-white px-3 4k:px-10 space-x-5 rounded-md"}>
            <img src={SearchIcon} className={"2k:w-10 4k:w-16"} alt="Search Icon"/>
            <input
                className={"w-full h-9 outline-none placeholder:text-dark 2k:text-2xl 4k:text-4xl"}
                value={search}
                placeholder={"Search"}
                type={"text"}
                onChange={(e) => onSearchHandler(e.target.value)}
                onKeyDown={(e) => inputHandler(e.key)}
            />
        </div>
    )
}

export default SearchComponent
