import React from 'react';
import {Route, Routes} from "react-router-dom";
import PublicEmail from "../pages/public-email";
import ComingSoon from "../pages/cooming-soon";
// import {authToken} from "../utils/general-variable";
// import AllProjects from "../pages/all-projects";
// import Login from "../pages/login";
// import {PrivateRoute} from "./private-route";
// import ProjectDetail from "../pages/project-detail";
// import Admin from "../pages/admin";
// import ForgotPassword from "../pages/forgot-password";

export default function RoutePage() {
    // const isAuthenticated = authToken

    return (
        <Routes> <Route path="/" element={<ComingSoon/>}/>
            <Route path="/:id/:start/:end" element={<PublicEmail/>}/>
        </Routes>
        // <Routes>
        //     <Route path="/" element={!!isAuthenticated ? <AllProjects/> : <Login/>}/>
        //     <Route path="/" element={<ComingSoon/>}/>
        //     <Route path="/:id/:start/:end" element={<PublicEmail/>}/>
        //     <Route path="/project-detail/:id" element={<PrivateRoute children={<ProjectDetail/>}/>}/>
        //     <Route path="/admin" element={<PrivateRoute children={<Admin/>}/>}/>
        //     <Route path="/forgot-password" element={<ForgotPassword/>}/>
        // </Routes>
    )
}
