import React from "react";
import {CoffeeIcon, LoveIcon} from "../utils/image";

const FooterComponent = () => {
    return (
        <div className={"h-12 w-full row items-center justify-center row space-x-2 2k:space-x-4 py-5 text-dark 2k:text-xl"}>
            <p>Made with</p>
            <img src={LoveIcon} className={"2k:w-4"} alt="Love Icon"/>
            <p>and</p>
            <img src={CoffeeIcon} className={"2k:w-4"} alt="Coffee Icon"/>
            <p>by Arctic Grey</p>
        </div>
    )
}

export default FooterComponent
