import React, {useState} from "react";
import {Listbox, Transition} from '@headlessui/react'
import {useDispatch} from "react-redux";
import {setToolsCurrentPage, setToolsPageSize, setToolsSort, setToolsSortType} from "../reducers/tools.reducer";
import {ChevronIcon} from "../utils/image";

const SortComponent = (props) => {
    const dispatch = useDispatch()
    const {prefix, dataList, slug} = props
    const [selected, setSelected] = useState(dataList[0])

    const onChangeSelect = (item) => {
      if(slug === 'sort'){
          let textSplit = item.key.split('-')
          dispatch(setToolsSort(textSplit[0]))
          dispatch(setToolsSortType(textSplit[1] === 'true'))
      } else {
          dispatch(setToolsPageSize(item.key))
          dispatch(setToolsCurrentPage(1))
      }
    }

    return (
        <div className={"row items-center 2k:text-2xl 4k:text-4xl"}>
            {!!prefix && <p className={"text-dark opacity-40 mr-3 4k:mr-10 hidden md:block"}>{prefix}</p>}
            <div className={"flex-1"}>
                <Listbox value={selected.key} onChange={(e) => setSelected(e)}>
                    <div className="relative">
                        <Listbox.Button
                            className={`relative w-full row cursor-pointer items-center justify-between bg-white border h-10 2k:h-16 4k:h-28 md:space-x-3 md:px-5 2k:px-7 4k:px-20 px-3 rounded-md`}>
                            <div>{selected.name}</div>
                            <img src={ChevronIcon} alt="Chevron Icon"/>
                        </Listbox.Button>
                        <Transition
                            leave="transition ease-in duration-100"
                            leaveFrom="block"
                            leaveTo="hidden"
                        >
                            <Listbox.Options
                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {dataList.map((person, personIdx) => (
                                    <Listbox.Option
                                        key={personIdx}
                                        onClick={() => onChangeSelect(person)}
                                        className={({active}) =>
                                            `relative cursor-default text-xs select-none py-2 px-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`}
                                        value={person}
                                    >
                                        <div
                                            className={`block truncate`}>
                                            {person.name}
                                        </div>
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </div>
    )
}

export default SortComponent
