import React, {Fragment, useEffect, useState} from "react";
import {
    convertDateToCustomFormat,
    convertUnixToMoment,
    formatDateEndOfUnix,
    formatDateStartOfUnix,
    formatDateToReadable
} from "../utils/general-variable";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Menu, Transition} from '@headlessui/react'
import {setToolsDateRange} from "../reducers/tools.reducer";
import {ArrowLeftIcon, ArrowRightIcon} from "../utils/image";
import {useParams} from "react-router-dom";

const DatepickerComponent = (props) => {
    const {start: startParams, end: endParams} = useParams();
    const {activeButton, onChangeActiveButton} = props
    const dispatch = useDispatch()
    const {showDatePicker} = useSelector(state => state.toolsReducer)
    const dateMoment = moment()
    const [today, setToday] = useState(dateMoment)
    const [dateArray, setDateArray] = useState([])
    const [dateBeforeArray, setDateBeforeArray] = useState([])
    const [dateAfterArray, setDateAfterArray] = useState([])
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)

    useEffect(() => {
        let monthBeforeArray = []
        let array = []
        let dateAfterArray = []
        let y = moment(today).get('year')
        let m = moment(today).get('month')
        const dateStart = new Date(y, m).getDay()
        let dateEnd = 40 - (new Date(y, m, 40).getDate())

        //generate array for before this month
        let dateBeforeEnd = 40 - (new Date(constructYearNew(y, m - 1), constructMonthNew(m - 1), 40).getDate())
        for (let i = dateBeforeEnd - (dateStart - 1); i <= dateBeforeEnd; i++) {
            let day = new Date(constructYearNew(y, m - 1), constructMonthNew(m - 1), i);
            let c = moment(day).startOf('days')
            monthBeforeArray.push(c)
        }

        //generate array for this month
        for (let i = 1; i <= dateEnd; i++) {
            let day = new Date(y, constructMonthNew(m), i);
            let c = moment(day)
            array.push(c)
        }

        //generate array for after this month
        let totalDate = monthBeforeArray.length + array.length
        let dateLeft = 42 - totalDate
        for (let i = 1; i <= dateLeft; i++) {
            let day = new Date(constructYearNew(y, m + 2), constructMonthNew(m + 1), i);
            let c = moment(day)
            dateAfterArray.push(c)
        }

        setDateArray(array)
        setDateBeforeArray(monthBeforeArray)
        setDateAfterArray(dateAfterArray)
    }, [today])

    const constructYearNew = (y, m) => {
        if (m < 0) {
            return y - 1
        } else if (m > 11) { 
            return y + 1
        }
        return y
    }
    
    const constructMonthNew = (m) => {
        if (m < 0) { 
            return 11 + (m + 1) 
        } else if (m > 11) { 
            return m - 12
        }
        return m
    }

    const dateButtonHandler = (item) => {
        getDateRangePicker(item)
    }

    const getDateRangePicker = (item) => {
        if (!!start && !end) {
            setEnd(formatDateEndOfUnix(item))
            dispatch(setToolsDateRange([start, formatDateEndOfUnix(item)]))
            activeButtonHandler()
        } else if (!!start && !!end) {
            setStart(formatDateStartOfUnix(item))
            setEnd(null)
        } else {
            setStart(formatDateStartOfUnix(item))
        }
    }

    const decreaseIncreaseMonth = (type) => {
        let a = null;
        if (type === 'minus') {
            a = moment(today).subtract(1, 'months')
        } else {
            a = moment(today).add(1, 'months')
        }
        setToday(a)
    }

    const clearButtonHandler = () => {
        dispatch(setToolsDateRange([startParams, endParams]))
        onChangeActiveButton('week')
        setStart(null)
        setEnd(null)
        setIsOpen(false)
    }

    const activeButtonHandler = () => {
        onChangeActiveButton('date-range')
        openModal()
    }

    const renderButton = (item, index) => (
        <button key={index}
                onClick={() => dateButtonHandler(item)}
                disabled={moment(item).isAfter()}
                className={`row justify-center items-center disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed hover:bg-christmas-green hover:text-white text-dark h-7 ${moment(item).isSame(dateMoment, 'day') && 'border border-dark-purple'} ${moment(item).isSame(convertUnixToMoment(start), 'day') && 'bg-christmas-green text-white rounded-l-full'} ${moment(item).isSame(convertUnixToMoment(end), 'day') && 'bg-christmas-green text-white rounded-r-full'} ${moment(item).isBetween(convertUnixToMoment(start), convertUnixToMoment(end), 'day') && 'bg-christmas-green text-white'}`}>
            <p className={"text-sm font-Poppins-Medium"}>{moment(item).date()}</p>
        </button>
    )
    let [isOpen, setIsOpen] = useState(false)

    function closeModalDatePicker(text) {
        if (text === 'out') {
            setIsOpen(false)
        } else {
            if (!!start && !end) {
                setIsOpen(false)
            }
        }
    }

    function openModal() {
        setIsOpen(true)
    }

    // function closeModal() {
    //     setIsOpen(false)
    // }

    return (
        <div className="relative">
            <Menu>
                <Menu.Button
                    onClick={activeButtonHandler}
                    className={`${showDatePicker || activeButton === 'date-range' ? 'bg-white shadow-sm' : 'bg-dark bg-opacity-10'} text-dark 2xl:text-10 lg:text-[9px] 2k:text-2xl 2k:h-12 2k:h-16 font-medium h-6 2xl:px-2.5 lg:px-1 rounded 4k:text-4xl 4k:px-10 4k:rounded-md`}>Date
                    Range
                </Menu.Button>
                <Transition appear show={isOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        onClick={() => closeModalDatePicker('out')}
                    >
                        <div className="fixed inset-0 bg-transparent"/>
                    </Transition.Child>
                    <div onClick={() => closeModalDatePicker('in')} className={"relative z-20"}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={`z-10 absolute mt-3 lg:w-[338px] w-[250px] 4k:w-[800px] lg:-ml-64 4k:-ml-400 -ml-44`}>
                                <Menu.Items>
                                    <div
                                        className={"grid grid-cols-2 gap-5 bg-christmas-green px-2 py-2 text-xs rounded-t shadow-2xl"}>
                                        <div
                                            className={"bg-white row justify-center text-10 4k:text-4xl font-Poppins-Medium py-2 rounded h-8"}>
                                            {!!start ? formatDateToReadable(start, 'LL') :
                                                <p className={"text-gray-400"}>July 1, 2022</p>}
                                        </div>
                                        <div
                                            className={"bg-white row justify-center text-10 4k:text-4xl font-Poppins-Medium py-2 rounded h-8"}>
                                            {!!end ? formatDateToReadable(end, 'LL') :
                                                <p className={"text-gray-400"}>July 1, 2022</p>}
                                        </div>
                                    </div>
                                    <div className={"bg-white rounded-b lg:px-10 px-3 py-2 space-y-3 shadow-xl"}>
                                        <div className={"row items-center justify-between"}>
                                            <img src={ArrowLeftIcon} className={"cursor-pointer"}
                                                 onClick={() => decreaseIncreaseMonth('minus')} alt="arrow left"/>
                                            <p className={"font-Poppins-SemiBold text-sm 4k:text-4xl"}>{convertDateToCustomFormat(today, 'MMMM YYYY')}</p>
                                            {
                                                !moment(today).isSame(moment(), 'month') ?
                                                    <img src={ArrowRightIcon} className={"cursor-pointer"}
                                                         onClick={() => decreaseIncreaseMonth('plus')} alt="arrow right"/> : <div/>
                                            }
                                        </div>
                                        <div className={"grid grid-cols-7"}>
                                            {
                                                ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((item, index) => (
                                                    <div key={index}
                                                         className={"row justify-center items-center h-8 w-8"}>
                                                        <p className={"text-10 text-light-grey-two font-Poppins-Medium"}>{item}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className={"grid grid-cols-7 gap-y-1"}>
                                            {
                                                dateBeforeArray.map((item, index) => (
                                                    <button key={index}
                                                            onClick={() => dateButtonHandler(item)}
                                                            className={`row justify-center items-center disabled:bg-gray-300 text-gray-400 h-7 hover:bg-christmas-green hover:text-white ${moment(item).isSame(convertUnixToMoment(start), 'day') && 'bg-christmas-green text-white rounded-l-full'} ${moment(item).isSame(convertUnixToMoment(end), 'day') && 'bg-christmas-green text-white rounded-r-full'} ${moment(item).isBetween(convertUnixToMoment(start), convertUnixToMoment(end), 'day') && 'bg-christmas-green text-white'}`}>
                                                        <p className={"text-sm font-Poppins-Medium"}>{moment(item).date()}</p>
                                                    </button>
                                                ))
                                            }
                                            {dateArray.map((item, index) => renderButton(item, index))}
                                            {
                                                dateAfterArray.map((item, index) => (
                                                    <button key={index}
                                                            disabled={moment(item).isAfter()}
                                                            onClick={() => dateButtonHandler(item)}
                                                            className={`row justify-center items-center disabled:bg-gray-300 text-gray-400 h-7 hover:bg-christmas-green hover:text-white ${moment(item).isSame(convertUnixToMoment(start), 'day') && 'bg-christmas-green text-white rounded-l-full'} ${moment(item).isSame(convertUnixToMoment(end), 'day') && 'bg-christmas-green text-white rounded-r-full'} ${moment(item).isBetween(convertUnixToMoment(start), convertUnixToMoment(end), 'day') && 'bg-christmas-green text-white'}`}>
                                                        <p className={"text-sm font-Poppins-Medium"}>{moment(item).date()}</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        <div className={"row justify-end"}>
                                            <Menu.Item>
                                                {({close}) => (
                                                    <button onClick={() => {
                                                        clearButtonHandler()
                                                    }}>Clear</button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </div>
                                </Menu.Items>
                            </div>
                        </Transition.Child>
                    </div>
                </Transition>
            </Menu>
        </div>

    )
}

export default DatepickerComponent
